import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import LaunchIcon from '@mui/icons-material/Launch';

// Generate Order Data
function createData(id, name, gross) {
  return { id, name, gross };
}

const rows = [
  createData(
    0,
    'Argylle',
    '6.2M',
  ),
  createData(
    1,
    'Lisa Frankenstein',
    '3.7M',
  ),
  createData(
    2,
    'The Beekeeper',
    '3.4M',
  ),
  createData(
    3,
    'Wonka',
    '3.1M',
  ),
  createData(
    4,
    'Migration',
    '2.9M',
  ),
];

export default function Weekend() {
  return (
    <React.Fragment>
      <Title>Latest Weekend (9-11 Feb 2024)</Title>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ "& th": { fontWeight: 'bold' }}}>
            <TableCell>Name</TableCell>
            <TableCell align="right">Gross</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">${row.gross}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="https://www.boxofficemojo.com/weekend/2024W06/" target="_blank" sx={{ mt: 3 }}>
        Full details <LaunchIcon fontSize='small'/>
      </Link>
    </React.Fragment>
  );
}
