import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import LaunchIcon from '@mui/icons-material/Launch';

// Generate Order Data
function createData(id, date, name, theaters) {
    return { id, date, name, theaters };
  }
  
  const rows = [
    createData(
      0,
      '16 Feb 2024',
      'Bleeding Love',
      'Limited',
    ),
    createData(
      1,
      '16 Feb 2024',
      'Land of Bad',
      'Limited',
    ),
    createData(
      2,
      '16 Feb 2024',
      'Altered Reality',
      'Limited',
    ),
    createData(
      3,
      '16 Feb 2024',
      'God & Country',
      'Limited',
    ),
    createData(
      4,
      '18 Feb 2024',
      'Dune (40th Anniversary Re-release)',
      'Limited',
    ),
    createData(
      5,
      '21 Feb 2024',
      'Final Fantasy VII: Advent Children',
      'Limited',
    ),
  ];

// function preventDefault(event) {
//   event.preventDefault();
// }

export default function Upcoming() {
  return (
    <React.Fragment>
      <Title>Upcoming Releases</Title>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ "& th": { fontWeight: 'bold' }}}>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Theaters</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">{row.theaters}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="https://www.boxofficemojo.com/calendar/" target="_blank" sx={{ mt: 3 }}>
        Full calendar <LaunchIcon fontSize='small'/>
      </Link>
    </React.Fragment>
  );
}
